<template>
  <div class="row">
        <div class="col-md-12">
            <h4>Bestellungen</h4>
            <p>Cooming Soon</p>
        </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>